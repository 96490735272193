import React, { useState, useEffect } from "react";
import { ReactComponent as AddUserIcon } from "../../../images/add-user.svg";
import { ReactComponent as AlertIcon } from "../../../images/alert-circle.svg";
import "./addUser.scss";
import Modal from "../../../common/modal/modal.tsx";
import UserSearchDropdown, {
  UserSearchDropdownItem,
} from "../../../common/dropdown/userSearchDropdown.tsx";
import { useDispatch, useSelector } from "react-redux";
import {
  searchUserByName,
  addUser,
  editUser,
  loadUsers,
  loadGroups,
  loadRoles,
} from "../userSlice.ts";
import { RootState, AppDispatch } from "../../../store";
import { UserItem } from "../userService.ts";
import SuccessfullToast from "../../../common/toast/successfulToast.tsx";
import ChangePassword from "../password/changePassword.tsx";
import GroupDropdown from "./userRoot/groupDropdown.tsx";
import Dropdown, { DropdownItems } from "../../../common/dropdown/dropdown.tsx";
import Tooltip from "../../../common/tooltip/tooltip.tsx";
import ConfirmationDialog from "../../../common/confirmationDialog/confirmationDialog.tsx";
import Loader from "../../../common/loader/loader.tsx";
import AddCustomer from "../../customerDetails/addCustomerDetails/addCustomerDetails.tsx";
import {
  addCustomer,
  loadCustomer,
} from "../../customerDetails/customerSlice.ts";
import { CustomerItem } from "../../customerDetails/customerService.ts";
import { useFormChangeDetection } from "../../../common/formChangeDetection/formChangeDetection.tsx";

interface AddUserProps {
  addUser: (newUser: UserItem) => void;
  editUser: (updatedUser: UserItem) => void;
  userToEdit?: UserItem | null;
}

const AddUser: React.FC<AddUserProps> = ({ userToEdit }) => {
  const dispatch = useDispatch<AppDispatch>();
  const searchResults = useSelector(
    (state: RootState) => state.users.searchResults
  );
  const userSearchLoading = useSelector(
    (state: RootState) => state.users.userSearchLoading
  );
  const groups = useSelector((state: RootState) => state.users.groups);
  const roles = useSelector((state: RootState) => state.users.roles);
  const users = useSelector((state: RootState) => state.users.users);
  const userSaveLoading = useSelector(
    (state: RootState) => state.users.userSaveLoading
  );
  const customerNames = useSelector(
    (state: RootState) => state.customers.customer
  );

  const initialFormData = {
    id: "",
    createdBy: "",
    createdOnDate: "",
    lastModifiedBy: "",
    lastModifiedDate: "",
    UserId: "",
    cnX_UserId: null,
    customerName: "",
    customerId: null,
    isEnabled: false,
    userType: "",
    userName: "",
    password: "",
    contactId: "",
    lastLogin: "",
    grafaEditor: "",
    license: {
      licenseType: "",
      expirationDate: "",
    },
    b2cdomainname: "",
    mostCommonPreferences: "",
    mustChangePassword: true,
    sso: false,
    userPreference: {
      id: "",
      createdBy: "",
      createdOnDate: "",
      lastModifiedBy: "",
      lastModifiedDate: "",
      userId: "",
      preferenceItem: {
        groupName: "",
        propertyItem: [],
      },
    },
    userRoles: {
      id: "",
      createdBy: "",
      createdOnDate: "",
      lastModifiedBy: null,
      lastModifiedDate: "",
      roleIds: [],
      userId: "",
    },
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState<UserItem>(initialFormData);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [UserSearchDropdownItems, setUserSearchDropdownItems] = useState<
    UserSearchDropdownItem[]
  >([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [textcolor, setTextColor] = useState("");
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [previousValue, setPreviousValue] = useState("");
  const [dataLoaded, setDataLoaded] = useState(false);
  const { setOriginalFormData, hasChanges } =
    useFormChangeDetection<CustomerItem>(null);

  useEffect(() => {
    if (!dataLoaded) {
      if (customerNames.length === 0) {
        dispatch(loadCustomer());
      }
      if (groups.length === 0) {
        dispatch(loadGroups());
      }
      if (roles.length === 0) {
        dispatch(loadRoles());
      }
      setDataLoaded(true);
    }
  }, [dispatch, dataLoaded, customerNames.length, groups.length, roles.length]);

  useEffect(() => {
    if (userToEdit) {
      setFormData({
        ...userToEdit,
        id: userToEdit.id.toString(),
        license: userToEdit.license || [
          {
            licenseType: "",
            expirationDate: "",
          },
        ],
      });
      setOriginalFormData({ ...userToEdit });
      setIsEditMode(true);
      openModal();
    }
  }, [userToEdit, setOriginalFormData]);

  useEffect(() => {
    let isValid = true;
    const newErrors: { [key: string]: string } = {};

    if (!isEditMode) {
      isValid =
        formData.userName.trim() !== "" &&
        formData.customerName.trim() !== "" &&
        formData.userPreference?.preferenceItem?.groupName.trim() !== "";

      if (!formData.sso) {
        isValid =
          isValid &&
          formData.password.trim() !== "" &&
          confirmPassword.trim() !== "";

        if (formData.password && formData.password.length < 5) {
          newErrors.password =
            "Password needs to be at least 5 characters long";
        }

        if (confirmPassword && formData.password !== confirmPassword) {
          newErrors.confirmPassword = "Passwords do not match";
        }
      }

      if (
        formData.userName &&
        users.some(
          (user) =>
            user.userName &&
            user.userName.toLowerCase() === formData.userName.toLowerCase()
        )
      ) {
        newErrors.userName = "User name already exists";
      }
    }

    if (formData.sso && !formData.b2cdomainname) {
      newErrors.b2cdomainname =
        "B2CDomain Name is required when SSO is enabled";
    }

    setErrors(newErrors);
    setIsFormValid(isValid && Object.keys(newErrors).length === 0);
  }, [formData, confirmPassword, users, isEditMode]);

  useEffect(() => {
    setInputValue(formData.userName);
  }, [formData.userName]);

  useEffect(() => {
    if (inputValue.length > 2) {
      const filteredItems = searchResults.filter((result) =>
        result.email.toLowerCase().includes(inputValue.toLowerCase())
      );
      setUserSearchDropdownItems(
        filteredItems.map((result, index) => ({
          id: result.id,
          name: result.email,
          displayName: result.email,
          sso: result.sso,
          passwordHash: result.passwordHash,
          key: `${result.id}-${index}`,
        }))
      );
    }
  }, [searchResults, inputValue]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setFormData(initialFormData);
    setConfirmPassword("");
    setErrors({});
    setIsEditMode(false);
    setUserSearchDropdownItems([]);
    setInputValue("");
  };

  const openAddUserModal = () => {
    setIsEditMode(false);
    setOriginalFormData(initialFormData);
    setFormData(initialFormData);
    openModal();
    setUserSearchDropdownItems([]);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => {
      const updatedFormData = {
        ...prevState,
        [name]: type === "checkbox" ? checked : value.replace(/^\s+/, ""),
      };

      if (name === "sso" && !checked) {
        updatedFormData.b2cdomainname = "";
      }

      if (name === "sso" && checked) {
        updatedFormData.password = "";
        setConfirmPassword("");
      }

      return updatedFormData;
    });
  };

  const handleDropdownChange = (selectedUser: UserSearchDropdownItem) => {
    setFormData((prevState) => ({
      ...prevState,
      cnX_UserId: String(selectedUser.id),
      userName: selectedUser.name,
      sso: selectedUser.sso ?? false,
      password: selectedUser.passwordHash || "",
      isEnabled: true,
    }));
    setInputValue(selectedUser.name);
  };

  const handleCustomerDropdownChange = (item: DropdownItems, field: string) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: item.name,
      ...(field === "customerName" && { customerId: item.id }),
    }));
  };

  const addCustomerHandler = (newCustomer: CustomerItem) => {
    dispatch(addCustomer(newCustomer));
  };

  const customNoResultsMessage = (
    <>
      <p className="no-customer-found">Customer not listed?</p>
      <span className="add-new-customer">
        <AddCustomer addCustomer={addCustomerHandler} triggerType="button" />
      </span>
    </>
  );

  const handleUserSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const currentDate = new Date().toISOString();

    const updatedFormData: UserItem = {
      ...formData,
      cnX_UserId: formData.cnX_UserId ?? null,
      createdOnDate: formData.createdOnDate
        ? formData.createdOnDate
        : currentDate,
      lastModifiedDate: currentDate,
      lastLogin: formData.lastLogin || currentDate,
      license: {
        licenseType: formData.license.licenseType || "",
        expirationDate: formData.license.expirationDate || currentDate,
      },
      userPreference: {
        ...formData.userPreference,
        id: formData.userPreference.id,
        createdOnDate: "2024-06-13T22:43:20.594Z",
        lastModifiedDate: "2024-06-13T22:43:20.594Z",
        userId: formData.id,
      },
      userRoles: {
        ...formData.userRoles,
        id: formData.userRoles.id,
        createdOnDate: "2024-06-13T22:42:44.306Z",
        lastModifiedDate: "2024-06-13T22:42:44.306Z",
        userId: formData.id,
      },
    };

    if (Object.keys(errors).length === 0) {
      try {
        const action = isEditMode
          ? editUser({
              userId: formData.id,
              updatedUser: updatedFormData,
            })
          : addUser(updatedFormData);

        await dispatch(action).unwrap();
        setToastMessage(
          `User ${isEditMode ? "updated" : "added"} successfully`
        );
        setTextColor("green");
        dispatch(loadUsers());
      } catch (error) {
        setToastMessage(
          `Failed to ${isEditMode ? "update" : "add"} user. Please try again.`
        );
        setTextColor("red");
      } finally {
        setShowToast(true);
        closeModal();
      }
    }
  };

  const handleInputChangeLogging = async (value: string) => {
    const trimmedValue = value.replace(/^\s+/, "");
    setInputValue(trimmedValue);
    setFormData((prevState) => ({
      ...prevState,
      userName: trimmedValue,
    }));

    if (trimmedValue.length === 3) {
      if (trimmedValue !== previousValue) {
        dispatch(searchUserByName(trimmedValue));
        setPreviousValue(trimmedValue);
      }
    } else if (trimmedValue.length < 3) {
      setUserSearchDropdownItems([]);
      setPreviousValue("");
    }
  };

  const openPasswordModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsPasswordModalOpen(true);
  };

  const closePasswordModal = () => {
    setIsPasswordModalOpen(false);
  };

  const handleUserRootChange = (selectedRoot: DropdownItems) => {
    setFormData((prevState) => ({
      ...prevState,
      userPreference: {
        ...prevState.userPreference,
        preferenceItem: {
          ...prevState.userPreference.preferenceItem,
          groupName: selectedRoot.name,
        },
      },
    }));
  };

  const customerItems = customerNames
    .map((customer) => ({
      id: customer.id,
      name: customer.name || "",
    }))
    .sort((a, b) => (a.name || "").localeCompare(b.name || ""));

  const handleCancelClick = () => {
    if (hasChanges(formData)) {
      setIsConfirmationDialogOpen(true);
    } else {
      handleConfirmCancel();
    }
  };

  const handleConfirmationDialogClose = () =>
    setIsConfirmationDialogOpen(false);

  const handleConfirmCancel = () => {
    setIsConfirmationDialogOpen(false);
    closeModal();
  };

  const shouldShowPasswordFields = !formData.b2cdomainname && !formData.sso;

  return (
    <div>
      <Tooltip content="Add new user" direction="top">
        <span className="add-user-icon" onClick={openAddUserModal}>
          <AddUserIcon />
        </span>
      </Tooltip>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <div style={{ position: "relative" }}>
          {userSaveLoading && (
            <div className="spinner-overlay">
              <Loader isOverlay={true} />
            </div>
          )}
          <form className="add-user-form" onSubmit={handleUserSubmit}>
            <div className="form-row">
              <label style={{ fontSize: "20px" }}>
                {isEditMode ? "Edit User" : "Add New User"}
              </label>
            </div>
            <div className="form-row">
              <div className="form-group form-group-inline">
                <label htmlFor="sso">SSO:</label>
                <input
                  type="checkbox"
                  id="sso"
                  name="sso"
                  checked={
                    formData.b2cdomainname ? true : formData.sso || false
                  }
                  onChange={handleInputChange}
                  className="checkbox-input"
                />
              </div>

              <div className="form-group">
                <label>
                  <span>Customer Name:</span>
                  <span className="required-asterisk">*</span>
                </label>
                <Dropdown
                  items={customerItems}
                  defaultOption={formData.customerName || "Select customer"}
                  onChange={(item) =>
                    handleCustomerDropdownChange(item, "customerName")
                  }
                  disabled={isEditMode}
                  noResultsMessage={customNoResultsMessage}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>
                  <span>User Name:</span>
                  <span className="required-asterisk">*</span>
                </label>
                <UserSearchDropdown
                  items={UserSearchDropdownItems}
                  defaultOption={formData.userName}
                  onChange={handleDropdownChange}
                  onInputChange={handleInputChangeLogging}
                  inputValue={inputValue}
                  disabled={isEditMode}
                  error={!!errors.userName}
                  tooltipMessage={errors.userName}
                  isLoading={userSearchLoading}
                />
              </div>
              <div className="form-group">
                <label>
                  <span>User Root:</span>
                  <span className="required-asterisk">*</span>
                </label>
                <GroupDropdown
                  items={groups}
                  defaultOption={
                    formData.userPreference?.preferenceItem?.groupName ||
                    "Select a root"
                  }
                  onChange={handleUserRootChange}
                  inputValue={
                    formData.userPreference?.preferenceItem?.groupName || ""
                  }
                />
              </div>
            </div>
            {shouldShowPasswordFields && (
              <div className="form-row">
                {isEditMode ? (
                  <span className="form-change-password-button">
                    <div className="form-group">
                      <button
                        className="change-password-button"
                        onClick={openPasswordModal}
                      >
                        Change Password
                      </button>
                      <ChangePassword
                        isOpen={isPasswordModalOpen}
                        onClose={closePasswordModal}
                        id={formData.id}
                        userName={formData.userName}
                        cnX_UserId={formData.cnX_UserId || null}
                      />
                      <div className="form-group-password-check">
                        <label htmlFor="changePassword">
                          User must change password at first login:
                        </label>
                        <input
                          type="checkbox"
                          id="changePassword"
                          name="mustChangePassword"
                          checked={formData.mustChangePassword}
                          onChange={handleInputChange}
                          className="checkbox-input"
                          disabled={
                            formData.sso ||
                            formData.b2cdomainname?.trim() !== ""
                          }
                        />
                      </div>
                    </div>
                  </span>
                ) : (
                  <>
                    <div className="form-group">
                      <label>
                        <span>Password:</span>
                        {!formData.sso && (
                          <span className="required-asterisk">*</span>
                        )}
                      </label>
                      <div className="alert-icon-container">
                        <input
                          type="password"
                          name="password"
                          value={formData.password}
                          onChange={handleInputChange}
                        />
                        {errors.password && (
                          <span className="error-icon">
                            <Tooltip content={errors.password} direction="top">
                              <AlertIcon />
                            </Tooltip>
                          </span>
                        )}
                      </div>
                    </div>
                    {!isEditMode ? (
                      <div className="form-group">
                        <label>
                          <span>Confirm Password:</span>
                          {!formData.sso && (
                            <span className="required-asterisk">*</span>
                          )}
                        </label>
                        <div className="alert-icon-container">
                          <input
                            type="password"
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                          {errors.confirmPassword && (
                            <span className="error-icon">
                              <Tooltip
                                content={errors.confirmPassword}
                                direction="top"
                              >
                                <AlertIcon />
                              </Tooltip>
                            </span>
                          )}
                        </div>
                        <div className="form-group-password-check">
                          <label htmlFor="changePassword">
                            User must change password at first login:
                          </label>
                          <input
                            type="checkbox"
                            id="changePassword"
                            name="mustChangePassword"
                            checked={formData.mustChangePassword}
                            onChange={handleInputChange}
                            className="checkbox-input"
                            disabled={formData.sso}
                          />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            )}
            {!shouldShowPasswordFields && (
              <div className="form-row">
                <div className="form-group">
                  <label>
                    <span>B2C Domain:</span>
                    {formData.sso && (
                      <span className="required-asterisk">*</span>
                    )}
                  </label>
                  <input
                    type="text"
                    name="b2cdomainname"
                    value={formData.b2cdomainname || ""}
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                </div>
                <div className="form-group"></div>
              </div>
            )}
            <div className="form-row">
              <label>User Security Settings</label>
            </div>
            <div className="form-row">
              <span className="form-user-roles">
                {Array.isArray(roles) && roles.length > 0 ? (
                  roles.map((role, index) => (
                    <div className="settings-form-group" key={role.id}>
                      <input
                        type="checkbox"
                        id={`roles-${role.id}`}
                        name={`roles.${role.id}`}
                        checked={
                          formData.userRoles?.roleIds?.includes(role.id) ||
                          false
                        }
                        onChange={(e) => {
                          const roleId = role.id;
                          setFormData((prevState) => {
                            const checked = e.target.checked;
                            let updatedRoles = [
                              ...(prevState.userRoles?.roleIds || []),
                            ];

                            if (checked) {
                              updatedRoles.push(roleId);
                            } else {
                              updatedRoles = updatedRoles.filter(
                                (id) => id !== roleId
                              );
                            }

                            return {
                              ...prevState,
                              userRoles: {
                                ...prevState.userRoles,
                                roleIds: updatedRoles,
                              },
                            };
                          });
                        }}
                        className="settings-checkbox-input"
                      />
                      <label
                        htmlFor={`roles-${role.id}`}
                        className="settings-label"
                      >
                        {role.roleName}
                      </label>
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </span>
            </div>
            <div className="form-mandatory-message">
              <p className="mandatory-fields-message">
                Fields marked with<span className="required-asterisk">*</span>{" "}
                are mandatory.
              </p>
            </div>
            <div className="form-row" style={{ gap: "10px" }}>
              <button
                type="submit"
                className="add-user-button"
                disabled={
                  isEditMode
                    ? !isFormValid || !hasChanges(formData)
                    : !isFormValid
                }
              >
                {isEditMode ? "Save Changes" : "Add User"}
              </button>
              <button
                type="button"
                className="cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Modal>
      {showToast && (
        <SuccessfullToast
          message={toastMessage}
          isShowEnabled={showToast}
          setShow={setShowToast}
          colorText={textcolor}
        />
      )}
      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        onClose={handleConfirmationDialogClose}
        onConfirm={handleConfirmCancel}
        message="Are you sure you want to cancel? Any unsaved changes will be lost."
      />
    </div>
  );
};

export default AddUser;
