import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./userDetails.scss";
import { RootState, AppDispatch } from "../../store";
import {
  loadUsers,
  addUser,
  editUser,
  deleteUser,
  fetchUserById,
  resetUserToEdit,
} from "./userSlice.ts";
import AddUser from "./addUser/addUser.tsx";
import Tooltip from "../../common/tooltip/tooltip.tsx";
import { ReactComponent as Edit } from "../../images/edit-icon.svg";
import { ReactComponent as Delete } from "../../images/trash-icon.svg";
import CustomTable from "../../common/table/customTable.tsx";
import { UserItem } from "./userService.ts";
import SuccessfullToast from "../../common/toast/successfulToast.tsx";
import ConfirmationDialog from "../../common/confirmationDialog/confirmationDialog.tsx";
import { formatDateTime } from "../../common/dateConversion/dateUtils.tsx";

const UserDetails: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { users, userToEdit, userLoading, userDeleteLoading } = useSelector(
    (state: RootState) => state.users
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState<string | null>(null);
  const [userToDeleteName, setUserToDeleteName] = useState<string | null>(null);
  const [textcolor, setTextColor] = useState("");

  useEffect(() => {
    dispatch(loadUsers());
  }, [dispatch]);

  const handleEdit = useCallback(
    async (user: UserItem) => {
      try {
        await dispatch(fetchUserById(user.id));
        dispatch(resetUserToEdit());
      } catch (error) {
        console.error("Failed to fetch user by id", error);
      }
    },
    [dispatch]
  );

  const columns = useMemo(
    () => [
      { Header: "ID", accessor: "id" },
      { Header: "Name", accessor: "userName", width: "200px" },
      { Header: "B2C Domain", accessor: "b2cdomainname" },
      {
        Header: "Created On",
        accessor: "createdOnDate",
        Cell: ({ value }: { value: string }) => formatDateTime(value),
      },
      {
        Header: "Last Modified On",
        accessor: "lastModifiedDate",
        Cell: ({ value }: { value: string }) => formatDateTime(value),
      },
      {
        Header: "Is Enabled",
        accessor: "isEnabled",
        Cell: ({ value }: { value: boolean }) => (value ? "Yes" : "No"),
      },
      {
        Header: "Actions",
        Cell: ({ row }: { row: any }) => (
          <div className="actions">
            {row.original.isEnabled ? (
              <Tooltip content="Edit user" direction="top">
                <div onClick={() => handleEdit(row.original)}>
                  <Edit style={{ stroke: "#fff" }} />
                </div>
              </Tooltip>
            ) : (
              <Tooltip content="Cannot edit disabled user" direction="left">
                <Edit style={{ stroke: "#6c757d" }} />
              </Tooltip>
            )}
            {row.original.isEnabled ? (
              <Tooltip content="Delete user" direction="left">
                <div
                  onClick={() => {
                    setUserToDelete(row.original.id);
                    setUserToDeleteName(row.original.userName);
                    setShowDeleteModal(true);
                  }}
                >
                  <Delete style={{ stroke: "#fff" }} />
                </div>
              </Tooltip>
            ) : (
              <Tooltip content="Cannot delete disabled user" direction="left">
                <div className="disabled-icon">
                  <Delete style={{ stroke: "#6c757d" }} />
                </div>
              </Tooltip>
            )}
          </div>
        ),
      },
    ],
    [handleEdit]
  );

  const handleDelete = async () => {
    if (userToDelete) {
      try {
        const action = deleteUser(userToDelete);
        await dispatch(action).unwrap();
        setToastMessage("User deleted successfully");
        setTextColor("green");
        dispatch(loadUsers());
      } catch (error) {
        setToastMessage("Failed to delete user");
        setTextColor("red");
      } finally {
        setShowToast(true);
        setShowDeleteModal(false);
        setUserToDelete(null);
        setUserToDeleteName(null);
      }
    }
  };

  const addUserHandler = (newUser: UserItem) => {
    dispatch(addUser(newUser));
  };

  const editUserHandler = (updatedUser: UserItem) => {
    dispatch(editUser({ userId: updatedUser.id, updatedUser }));
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setCurrentPageIndex(0);
  };

  return (
    <div className="page-layout">
      <div className="main-header">
        <div className="header-name">User List</div>
      </div>
      <div className="main-content">
        {showToast && (
          <SuccessfullToast
            message={toastMessage}
            isShowEnabled={showToast}
            setShow={setShowToast}
            colorText={textcolor}
          />
        )}
        <CustomTable
          headerComponent={
            <AddUser
              addUser={addUserHandler}
              editUser={editUserHandler}
              userToEdit={userToEdit}
            />
          }
          data={users}
          columns={columns}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          searchQuery={searchQuery}
          onSearch={handleSearch}
          currentPageIndex={currentPageIndex}
          setCurrentPageIndex={setCurrentPageIndex}
          isLoading={userLoading}
          csvFileName="user_data.csv"
        />
        <ConfirmationDialog
          isOpen={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={handleDelete}
          message={`Are you sure you want to delete: ${userToDeleteName} ?`}
          isLoading={userDeleteLoading}
        />
      </div>
    </div>
  );
};

export default UserDetails;
