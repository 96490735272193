import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../store";
import {
  loadCustomer,
  addCustomer,
  editCustomer,
  deleteCustomer,
  fetchCustomerById,
  resetCustomerToEdit,
} from "./customerSlice.ts";
import AddCustomer from "./addCustomerDetails/addCustomerDetails.tsx";
import Tooltip from "../../common/tooltip/tooltip.tsx";
import CustomTable from "../../common/table/customTable.tsx";
import { CustomerItem } from "./customerService.ts";
import { ReactComponent as Edit } from "../../images/edit-icon.svg";
import { ReactComponent as Delete } from "../../images/trash-icon.svg";
import "../userDetails/userDetails.scss";
import SuccessfullToast from "../../common/toast/successfulToast.tsx";
import ConfirmationDialog from "../../common/confirmationDialog/confirmationDialog.tsx";
import { formatDateTime } from "../../common/dateConversion/dateUtils.tsx";

const CustomerDetails: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { customer, customerToEdit, customerLoading, customerDeleteLoading } =
    useSelector((state: RootState) => state.customers);

  const [searchQuery, setSearchQuery] = useState("");
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [customerToDelete, setCustomerToDelete] = useState<string | null>(null);
  const [customerToDeleteName, setCustomerToDeleteName] = useState<
    string | null
  >(null);
  const [textcolor, setTextColor] = useState("");

  useEffect(() => {
    dispatch(loadCustomer());
  }, [dispatch]);

  const handleEdit = useCallback(
    async (customer: CustomerItem) => {
      try {
        await dispatch(fetchCustomerById(customer.id));
        dispatch(resetCustomerToEdit());
      } catch (error) {
        console.error("Failed to fetch customer by id", error);
      }
    },
    [dispatch]
  );

  const columns = useMemo(
    () => [
      { Header: "ID", accessor: "id" },
      { Header: "Name", accessor: "name" },
      { Header: "B2C Domain", accessor: "b2Cdomainname" },
      {
        Header: "Created On",
        accessor: "createdOnDate",
        Cell: ({ value }: { value: string }) => formatDateTime(value),
      },
      {
        Header: "Last Modified On",
        accessor: "lastModifiedDate",
        Cell: ({ value }: { value: string }) => formatDateTime(value),
      },
      {
        Header: "Is Active",
        accessor: "isActive",
        Cell: ({ value }: { value: boolean }) => (value ? "Yes" : "No"),
      },
      {
        Header: "Actions",
        Cell: ({ row }: { row: any }) => (
          <div className="actions">
            {row.original.isActive ? (
              <Tooltip content="Edit customer" direction="top">
                <div onClick={() => handleEdit(row.original)}>
                  <Edit style={{ stroke: "#fff" }} />
                </div>
              </Tooltip>
            ) : (
              <Tooltip content="Cannot edit inactive customer" direction="left">
                <div>
                  <Edit style={{ stroke: "#6c757d" }} />
                </div>
              </Tooltip>
            )}
            {row.original.isActive ? (
              <Tooltip content="Delete customer" direction="left">
                <div
                  onClick={() => {
                    setCustomerToDelete(row.original.id);
                    setCustomerToDeleteName(row.original.name);
                    setShowDeleteModal(true);
                  }}
                >
                  <Delete style={{ stroke: "#fff" }} />
                </div>
              </Tooltip>
            ) : (
              <Tooltip
                content="Cannot delete inactive customer"
                direction="left"
              >
                <div className="disabled-icon">
                  <Delete style={{ stroke: "#6c757d" }} />
                </div>
              </Tooltip>
            )}
          </div>
        ),
      },
    ],
    [handleEdit]
  );

  const handleDelete = async () => {
    if (customerToDelete) {
      try {
        const action = deleteCustomer(customerToDelete);
        await dispatch(action).unwrap();
        setToastMessage("Customer deleted successfully");
        setTextColor("green");
        dispatch(loadCustomer());
      } catch (error) {
        setToastMessage("Failed to delete customer");
        setTextColor("red");
      } finally {
        setShowToast(true);
        setShowDeleteModal(false);
        setCustomerToDelete(null);
        setCustomerToDeleteName(null);
      }
    }
  };

  const addCustomerHandler = (newCustomer: CustomerItem) => {
    dispatch(addCustomer(newCustomer));
  };

  const editCustomerHandler = (updatedCustomer: CustomerItem) => {
    dispatch(editCustomer({ customerId: updatedCustomer.id, updatedCustomer }));
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setCurrentPageIndex(0);
  };

  return (
    <div className="page-layout">
      <div className="main-header">
        <div className="header-name">Customer List</div>
      </div>
      <div className="main-content">
        {showToast && (
          <SuccessfullToast
            message={toastMessage}
            isShowEnabled={showToast}
            setShow={setShowToast}
            colorText={textcolor}
          />
        )}
        <CustomTable
          headerComponent={
            <AddCustomer
              addCustomer={addCustomerHandler}
              editCustomer={editCustomerHandler}
              customerToEdit={customerToEdit}
              triggerType="icon"
            />
          }
          data={customer}
          columns={columns}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          searchQuery={searchQuery}
          onSearch={handleSearch}
          currentPageIndex={currentPageIndex}
          setCurrentPageIndex={setCurrentPageIndex}
          isLoading={customerLoading}
          csvFileName="customer_data.csv"
        />
        <ConfirmationDialog
          isOpen={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={handleDelete}
          message={`Are you sure you want to delete: ${customerToDeleteName} ?`}
          isLoading={customerDeleteLoading}
        />
      </div>
    </div>
  );
};

export default CustomerDetails;
