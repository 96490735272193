import React, { useState, useEffect } from "react";
import { ReactComponent as AddCustomerIcon } from "../../../images/add-customer-icon.svg";
import "./addCustomerDetails.scss";
import Modal from "../../../common/modal/modal.tsx";
import SearchDropdown, {
  searchDropdownItem,
} from "../../../common/dropdown/searchDropdown.tsx";
import { CustomerItem } from "../customerService.ts";
import { useDispatch, useSelector } from "react-redux";
import {
  searchCustomerByName,
  editCustomer,
  addCustomer,
  loadCustomer,
} from "../customerSlice.ts";
import { AppDispatch, RootState } from "../../../store";
import SuccessfullToast from "../../../common/toast/successfulToast.tsx";
import Tooltip from "../../../common/tooltip/tooltip.tsx";
import ConfirmationDialog from "../../../common/confirmationDialog/confirmationDialog.tsx";
import Loader from "../../../common/loader/loader.tsx";
import { ReactComponent as AlertIcon } from "../../../images/alert-circle.svg";
import { useFormChangeDetection } from "../../../common/formChangeDetection/formChangeDetection.tsx";

interface AddCustomerProps {
  customerToEdit?: CustomerItem | null;
  triggerType: "icon" | "button";
  addCustomer: (newCustomer: CustomerItem) => void;
  editCustomer?: (updatedCustomer: CustomerItem) => void;
}

const AddCustomer: React.FC<AddCustomerProps> = ({
  customerToEdit,
  triggerType,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const searchResults = useSelector(
    (state: RootState) => state.customers.searchResults
  );
  const customers = useSelector((state: RootState) => state.customers.customer);
  const customerSearchLoading = useSelector(
    (state: RootState) => state.customers.customerSearchLoading
  );
  const customerSaveLoading = useSelector(
    (state: RootState) => state.customers.customerSaveLoading
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const initialFormData: CustomerItem = {
    id: "",
    createdBy: "",
    createdOnDate: "",
    lastModifiedBy: "",
    lastModifiedDate: "",
    cnX_CustomerId: 0,
    cnX_CustomerName: "",
    name: "",
    isActive: false,
    b2Cdomainname: "",
  };

  const [formData, setFormData] = useState<CustomerItem>(initialFormData);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [searchDropdownItems, setSearchDropdownItems] = useState<
    searchDropdownItem[]
  >([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [textcolor, setTextColor] = useState("");
  const { setOriginalFormData, hasChanges } =
    useFormChangeDetection<CustomerItem>(null);

  useEffect(() => {
    if (customerToEdit) {
      setFormData({
        ...customerToEdit,
        id: customerToEdit.id.toString(),
      });
      setOriginalFormData({ ...customerToEdit });
      setIsEditMode(true);
      setSearchDropdownItems([]);
      openModal();
    }
  }, [customerToEdit, setOriginalFormData]);

  useEffect(() => {
    let isValid = true;
    const newErrors: { [key: string]: string } = {};

    isValid = formData.name.trim() !== "";

    if (
      formData.name &&
      customers.some(
        (customer) =>
          customer.name &&
          customer.name.toLowerCase() === formData.name.toLowerCase() &&
          (!isEditMode || customer.id !== formData.id)
      )
    ) {
      newErrors.name = "Customer name already exists";
    }

    setErrors(newErrors);
    setIsFormValid(isValid && Object.keys(newErrors).length === 0);
  }, [formData, isEditMode, customers]);

  useEffect(() => {
    setInputValue(formData.cnX_CustomerName);
  }, [formData.cnX_CustomerName]);

  useEffect(() => {
    if (inputValue.length > 2) {
      const filteredItems = searchResults.filter((result) =>
        result.name.toLowerCase().includes(inputValue.toLowerCase())
      );
      setSearchDropdownItems(
        filteredItems.map((result, index) => ({
          id: result.id,
          name: result.name,
          displayName: result.name,
          key: `${result.id}-${index}`,
        }))
      );
    }
  }, [searchResults, inputValue]);

  const openModal = () => setIsModalOpen(true);
  const closeCustomerModal = () => {
    setInputValue("");
    setIsModalOpen(false);
    setFormData(initialFormData);
    setErrors({});
    setIsEditMode(false);
  };

  const openAddCustomerModal = () => {
    setIsEditMode(false);
    setOriginalFormData(initialFormData);
    setFormData(initialFormData);
    openModal();
    setSearchDropdownItems([]);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value.replace(/^\s+/, ""),
    }));
  };

  const handleDropdownChange = (selectedCustomer: searchDropdownItem) => {
    setFormData((prevState) => ({
      ...prevState,
      cnX_CustomerId: selectedCustomer.id,
      cnX_CustomerName: selectedCustomer.name,
      name: !isEditMode ? selectedCustomer.name : prevState.name,
      isActive: true,
    }));
    setInputValue(selectedCustomer.name);
  };

  const handleCustomerSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const currentDate = new Date().toISOString();

    const updatedFormData: CustomerItem = {
      ...formData,
      createdOnDate: formData.createdOnDate
        ? formData.createdOnDate
        : currentDate,
      lastModifiedDate: currentDate,
      isActive: isEditMode ? formData.isActive : true,
    };

    if (Object.keys(errors).length === 0) {
      try {
        const action = isEditMode
          ? editCustomer({
              customerId: formData.id,
              updatedCustomer: updatedFormData,
            })
          : addCustomer(updatedFormData);

        await dispatch(action).unwrap();

        setToastMessage(`Customer ${isEditMode ? "updated" : "added"} successfully`);
        setTextColor("green");
        dispatch(loadCustomer());
      } catch (error) {
        setToastMessage(`Failed to ${isEditMode ? "update" : "add"} customer. Please try again.`);
        setTextColor("red");
      } finally {
        setShowToast(true); 
        closeCustomerModal();
      }
    }
  };

  const handleInputChangeLogging = async (value: string) => {
    const trimmedValue = value.replace(/^\s+/, "");
    setInputValue(trimmedValue);
    if (trimmedValue.length === 3) {
      dispatch(searchCustomerByName(trimmedValue));
    }
  };

  const handleCancelClick = () => {
    if (hasChanges(formData)) {
      setIsConfirmationDialogOpen(true);
    } else {
      handleConfirmCancel();
    }
  };

  const handleConfirmationDialogClose = () =>
    setIsConfirmationDialogOpen(false);

  const handleConfirmCancel = () => {
    setIsConfirmationDialogOpen(false);
    closeCustomerModal();
  };

  return (
    <div>
      {triggerType === "icon" ? (
        <Tooltip content="Add new customer" direction="top">
          <span className="add-user-icon" onClick={openAddCustomerModal}>
            <AddCustomerIcon />
          </span>
        </Tooltip>
      ) : (
        <button
          type="button"
          className="add-customer-name-button"
          onClick={openAddCustomerModal}
        >
          Add Customer
        </button>
      )}

      <Modal isOpen={isModalOpen} onClose={closeCustomerModal}>
        <div style={{ position: "relative" }}>
          {customerSaveLoading && (
            <div className="spinner-overlay">
              <Loader isOverlay={true} />
            </div>
          )}
          <form className="add-customer-form" onSubmit={handleCustomerSubmit}>
            <div className="form-row">
              <label style={{ fontSize: "20px" }}>
                {isEditMode ? "Edit Customer" : "Add New Customer"}
              </label>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>
                  <span>CNX Name:</span>
                </label>
                <SearchDropdown
                  items={searchDropdownItems}
                  defaultOption={
                    formData.cnX_CustomerName || "Select a customer"
                  }
                  onChange={handleDropdownChange}
                  onInputChange={handleInputChangeLogging}
                  inputValue={inputValue}
                  isLoading={customerSearchLoading}
                  infoMessage="Type 3 or more characters to search"
                />
              </div>
              <div className="form-group">
                <label>
                  <span>Name:</span>
                  <span className="required-asterisk">*</span>
                </label>
                <div className="alert-icon-container">
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                  {errors.name && (
                    <span className="error-icon">
                      <Tooltip content={errors.name} direction="top">
                        <AlertIcon />
                      </Tooltip>
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>B2C Domain:</label>
                <input
                  type="text"
                  name="b2Cdomainname"
                  value={formData.b2Cdomainname}
                  onChange={handleInputChange}
                  autoComplete="off"
                  style={{ width: "95%" }}
                />
              </div>
            </div>
            <div className="form-mandatory-message">
              <p className="mandatory-fields-message">
                Fields marked with<span className="required-asterisk">*</span>{" "}
                are mandatory.
              </p>
            </div>
            <div className="form-row" style={{ gap: "10px" }}>
              <button
                type="submit"
                className="add-customer-button"
                onClick={handleCustomerSubmit}
                disabled={
                  isEditMode
                    ? !isFormValid || !hasChanges(formData)
                    : !isFormValid
                }
              >
                {isEditMode ? "Save Changes" : "Add Customer"}
              </button>
              <button
                type="button"
                className="cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Modal>
      {showToast && (
        <SuccessfullToast
          message={toastMessage}
          isShowEnabled={showToast}
          setShow={setShowToast}
          colorText={textcolor}
        />
      )}
      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        onClose={handleConfirmationDialogClose}
        onConfirm={handleConfirmCancel}
        message="Are you sure you want to cancel? Any unsaved changes will be lost."
      />
    </div>
  );
};

export default AddCustomer;
