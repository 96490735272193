import axios from "axios";
import {
  getAPIBaseURL,
  getXSPOCAPIBaseURL,
} from "../utilities/baseUrlutility.tsx";
import TokenService from "./authentication/token.service.ts";

const baseUrl = getAPIBaseURL();
const xspocBaseUrl = getXSPOCAPIBaseURL();

const instance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
    [process.env.REACT_APP_XSPOC_SUBSCRIPTION_KEY_NAME as string]: process.env.REACT_APP_XSPOC_SUBSCRIPTION_KEY_VALUE,
  },
});

const xspocApiInstance = axios.create({
  baseURL: xspocBaseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config: any) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

xspocApiInstance.interceptors.request.use(
  (config: any) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error) {
    handleUnauthorized(error);
    return Promise.reject(error);
  }
);

xspocApiInstance.interceptors.response.use(
  (response: any) => response,
  (error) => {
    handleUnauthorized(error);
    return Promise.reject(error);
  }
);

const handleUnauthorized = (error: any) => {
  if (error.response && error.response.status === 401) {
    TokenService.removeUser();
    if (window.location.pathname !== "/login") {
      window.location.reload();
    }
  }
};

export default instance;
export { xspocApiInstance };
