import api, { xspocApiInstance } from "../../services/api.ts";

export interface License {
  licenseType: string;
  expirationDate: string;
}

export interface PreferenceItem {
  groupName: string;
  propertyItem: {
    propertyName: string;
    value: string;
  }[];
}

export interface UserPreference {
  id: string;
  createdBy: string;
  createdOnDate: string;
  lastModifiedBy: string | null;
  lastModifiedDate: string;
  userId: string;
  preferenceItem: PreferenceItem;
}

export interface UserRoles {
  id: string;
  createdBy: string;
  createdOnDate: string;
  lastModifiedBy: string | null;
  lastModifiedDate: string;
  roleIds: string[] | null;
  userId: string;
}

export interface UserItem {
  id: string;
  createdBy: string;
  createdOnDate: string;
  lastModifiedBy: string | null;
  lastModifiedDate: string;
  UserId: string | null;
  cnX_UserId: string | null;
  customerId: string | null;
  customerName: string | null;
  isEnabled: boolean;
  userType: string | null;
  userName: string;
  password: string;
  contactId: string | null;
  lastLogin: string | null;
  grafaEditor: string | null;
  license: License;
  sso: boolean;
  b2cdomainname: string | null;
  mostCommonPreferences: string | null;
  mustChangePassword: boolean;
  userPreference: UserPreference;
  userRoles: UserRoles;
}

export interface UserApiResponse {
  values: UserItem[];
  id: string;
  dateCreated: string;
}

export interface UserSearchResponse {
  id: string;
  firstName: string;
  lastName: string | null;
  middleName: string | null;
  phoneNumer: string | null;
  email: string;
  userName: string;
  passwordHash: string | null;
  sso: boolean;
  isActive: boolean;
}

export interface Group {
  groupName: string;
  childGroups: Group[];
}

export interface Role {
  id: string;
  roleName: string;
}

export interface UserDropdownItem {
  name: string;
  id: string;
  group?: Group;
}

export interface ChangePassword {
  id: string;
  userName: string;
  cnX_UserId: string | null;
  password: string;
}

const fetchUserDataAsync = async (): Promise<UserItem[]> => {
  try {
    const response = await xspocApiInstance.get<UserApiResponse[]>("/User", {
      headers: {
        "content-type": "application/json",
      },
    });

    if (response && Array.isArray(response.data.values)) {
      return response.data.values;
    } else {
      console.log("No data found in response", response);
      return [];
    }
  } catch (error) {
    console.error("Error fetching User data", error);
    return [];
  }
};

// Fetch user data by ID
const fetchUserDataByIdAsync = async (id: string): Promise<UserItem | null> => {
  try {
    const response = await xspocApiInstance.get<UserApiResponse>(
      `/User/${id}`,
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );

    if (response && response.data) {
      return response.data;
    } else {
      console.log("No data found in response", response);
      return null;
    }
  } catch (error) {
    console.error("Error fetching user data by id", error);
    return null;
  }
};

// Search User data by name from API
const searchUserByNameAsync = async (
  name: string
): Promise<UserSearchResponse[]> => {
  try {
    const response = await api.get<UserSearchResponse[]>(
      `/CNXUser/search/${name}`,
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );

    if (response && response.data) {
      return response.data;
    } else {
      console.log("No data found in response", response);
      return [];
    }
  } catch (error) {
    console.error("Error fetching search user by name data", error);
    return [];
  }
};

// Add new User
const addNewUserAsync = async (newUser: UserItem): Promise<UserItem | null> => {
  try {
    const response = await xspocApiInstance.post<UserItem>("/User", newUser, {
      headers: {
        "content-type": "application/json",
      },
    });

    if (response && response.data) {
      return response.data;
    } else {
      console.log("No data found in response", response);
      return null;
    }
  } catch (error) {
    console.error("Error adding new User", error);
    throw error;
  }
};

// Edit existing User
const editUserAsync = async (
  UserId: string,
  updatedUser: UserItem
): Promise<UserItem | null> => {
  try {
    const response = await xspocApiInstance.put<UserItem>(
      `/User?id=${UserId}`,
      updatedUser,
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );

    if (response && response.data) {
      return response.data;
    } else {
      console.log("No data found in response", response);
      return null;
    }
  } catch (error) {
    console.error("Error editing User", error);
    throw error;
  }
};

// Fetch user group data
const fetchGroupDataAsync = async (): Promise<Group[]> => {
  try {
    const response = await xspocApiInstance.get<Group[]>("/Group");
    if (response && response.data) {
      return response.data;
    } else {
      console.log("No data found in response", response);
      return [];
    }
  } catch (error) {
    console.error("Error fetching User Group data", error);
    return [];
  }
};

const fetchRoleDataAsync = async (): Promise<Role[]> => {
  try {
    const response = await xspocApiInstance.get<Role[]>("/Role");

    if (response && response.data) {
      return response.data;
    } else {
      console.log("No data found in response", response);
      return [];
    }
  } catch (error) {
    console.error("Error fetching Role data", error);
    return [];
  }
};

// Edit User Password
const changePasswordAsync = async (
  changePassword: ChangePassword
): Promise<ChangePassword | null> => {
  try {
    const response = await xspocApiInstance.put<ChangePassword>(
      `/User/ChangePassword`,
      changePassword,
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );

    if (response && response.data) {
      return response.data;
    } else {
      console.log("No data found in response", response);
      return null;
    }
  } catch (error) {
    console.error("Error changing user password", error);
    throw error;
  }
};

export {
  fetchUserDataAsync,
  fetchUserDataByIdAsync,
  searchUserByNameAsync,
  addNewUserAsync,
  editUserAsync,
  fetchGroupDataAsync,
  fetchRoleDataAsync,
  changePasswordAsync,
};
