import React, { useState, useEffect, useMemo } from "react";
import Modal from "../../../common/modal/modal.tsx";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store.ts";
import { changeUserPassword } from "../userSlice.ts";
import SuccessfullToast from "../../../common/toast/successfulToast.tsx";
import { ReactComponent as AlertIcon } from "../../../images/alert-circle.svg";
import Tooltip from "../../../common/tooltip/tooltip.tsx";
import ConfirmationDialog from "../../../common/confirmationDialog/confirmationDialog.tsx";

interface ChangePasswordProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: (newPassword: string) => void;
  id: string;
  userName: string;
  cnX_UserId: string | null;
}

const ChangePassword: React.FC<ChangePasswordProps> = ({
  isOpen,
  onClose,
  id,
  userName,
  cnX_UserId,
}) => {
  const initialFormData = useMemo(
    () => ({
      newPassword: "",
      confirmNewPassword: "",
    }),
    []
  );
  const dispatch = useDispatch<AppDispatch>();
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [isPasswordFormValid, setIsPasswordFormValid] = useState(false);
  const [textcolor, setTextColor] = useState("");
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  useEffect(() => {
    if (isOpen) {
      setFormData(initialFormData);
      setErrors({});
    }
  }, [isOpen, initialFormData]);

  useEffect(() => {
    let isValid = true;
    const newErrors: { [key: string]: string } = {};

    isValid =
      formData.newPassword.trim() !== "" &&
      formData.confirmNewPassword.trim() !== "";

    if (formData.newPassword && formData.newPassword.length < 5) {
      newErrors.newPassword = "Password needs to be at least 5 characters long";
    }

    if (
      formData.confirmNewPassword &&
      formData.newPassword !== formData.confirmNewPassword
    )
      newErrors.confirmNewPassword = "Passwords do not match";

    setErrors(newErrors);
    setIsPasswordFormValid(isValid && Object.keys(newErrors).length === 0);
  }, [formData]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (Object.keys(errors).length === 0) {
      try {
        await dispatch(
          changeUserPassword({
            id: id,
            userName: userName,
            cnX_UserId: cnX_UserId,
            password: formData.newPassword,
          })
        );
        setToastMessage("Password changed successfully");
        setTextColor("green");
        setShowToast(true);
        closeModal();
      } catch (error) {
        setToastMessage("Failed to change password. Please try again.");
        setTextColor("red");
        setShowToast(true);
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const closeModal = () => {
    onClose();
  };

  const handleCancelClick = () => {
    setIsConfirmationDialogOpen(true);
  };

  const handleConfirmationDialogClose = () => {
    setIsConfirmationDialogOpen(false);
  };

  const handleConfirmCancel = () => {
    setIsConfirmationDialogOpen(false);
    closeModal();
  };

  return (
    <div>
      <Modal isOpen={isOpen} onClose={closeModal}>
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group">
              <label style={{ fontSize: "20px" }}>Change Password</label>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>Username:</label>
              <input
                type="text"
                name="username"
                value={userName}
                disabled
                className="username-input"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>
                <span>New password:</span>
                <span className="required-asterisk">*</span>
              </label>
              <div className="alert-icon-container">
                <input
                  type="password"
                  name="newPassword"
                  value={formData.newPassword}
                  onChange={handleInputChange}
                />
                {errors.newPassword && (
                  <span className="error-icon">
                    <Tooltip content={errors.newPassword} direction="top">
                      <AlertIcon />
                    </Tooltip>
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>
                <span>Confirm Password:</span>
                <span className="required-asterisk">*</span>
              </label>
              <div className="alert-icon-container">
                <input
                  type="password"
                  name="confirmNewPassword"
                  value={formData.confirmNewPassword}
                  onChange={handleInputChange}
                />
                {errors.confirmNewPassword && (
                  <span className="error-icon">
                    <Tooltip
                      content={errors.confirmNewPassword}
                      direction="top"
                    >
                      <AlertIcon />
                    </Tooltip>
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="form-row" style={{ gap: "10px" }}>
            <button
              type="submit"
              className="add-user-button"
              onClick={handleSubmit}
              disabled={!isPasswordFormValid}
            >
              Save
            </button>
            <button
              type="button"
              className="cancel-button"
              onClick={handleCancelClick}
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal>
      {showToast && (
        <SuccessfullToast
          message={toastMessage}
          isShowEnabled={showToast}
          setShow={setShowToast}
          colorText={textcolor}
        />
      )}
      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        onClose={handleConfirmationDialogClose}
        onConfirm={handleConfirmCancel}
        message="Would you like to cancel? The password will not be changed."
      />
    </div>
  );
};

export default ChangePassword;
