import React, { useState, useEffect } from "react";
import { ReactComponent as AddAssetIcon } from "../../../images/add-asset-icon.svg";
import "./addAssetDetails.scss";
import Modal from "../../../common/modal/modal.tsx";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store.ts";
import {
  addAsset,
  editAsset,
  loadAsset,
  loadAssetControllerPort,
  loadAssetControllerType,
  searchAssetByName,
} from "../assetSlice.ts";
import Dropdown, { DropdownItems } from "../../../common/dropdown/dropdown.tsx";
import AddCustomer from "../../customerDetails/addCustomerDetails/addCustomerDetails.tsx";
import {
  addCustomer,
  loadCustomer,
} from "../../customerDetails/customerSlice.ts";
import { CustomerItem } from "../../customerDetails/customerService.ts";
import SearchDropdown, {
  searchDropdownItem,
} from "../../../common/dropdown/searchDropdown.tsx";
import { AssetItem } from "../assetService.tsx";
import SuccessfullToast from "../../../common/toast/successfulToast.tsx";
import Tooltip from "../../../common/tooltip/tooltip.tsx";
import ConfirmationDialog from "../../../common/confirmationDialog/confirmationDialog.tsx";
import { ReactComponent as AlertIcon } from "../../../images/alert-circle.svg";
import Loader from "../../../common/loader/loader.tsx";
import { useFormChangeDetection } from "../../../common/formChangeDetection/formChangeDetection.tsx";

interface AddAssetProps {
  addAsset: (newAsset: AssetItem) => void;
  editAsset: (updatedAsset: AssetItem) => void;
  assetToEdit?: AssetItem | null;
}

const AddAssetDetails: React.FC<AddAssetProps> = ({ assetToEdit }) => {
  const dispatch = useDispatch<AppDispatch>();
  const searchResults = useSelector(
    (state: RootState) => state.assets.searchResults
  );
  const assetSearchLoading = useSelector(
    (state: RootState) => state.assets.assetSearchLoading
  );
  const assets = useSelector((state: RootState) => state.assets.asset);
  const customerNames = useSelector(
    (state: RootState) => state.customers.customer
  );
  const controllerPorts = useSelector((state: RootState) => state.assets.port);
  const controllerTypes = useSelector((state: RootState) => state.assets.type);
  const assetSaveLoading = useSelector(
    (state: RootState) => state.assets.assetSaveLoading
  );
  const [isAssetModalOpen, setIsAssetModalOpen] = useState(false);
  const initialFormData = {
    id: "",
    customerId: null,
    customerName: "",
    createdOnDate: "",
    lastModifiedDate: "",
    cnX_WellName: "",
    name: "",
    cnX_SiteId: null,
    cnX_WellId: null,
    envWellId: null,
    apI_UWI: "",
    pocType: {
      id: "",
      legacyId: "",
      lookupType: "",
      description: "",
    },
    portId: "",
    nodeAddress: "",
    isEnabled: false,
  };
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [searchDropdownItems, setSearchDropdownItems] = useState<
    searchDropdownItem[]
  >([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [textcolor, setTextColor] = useState("");
  const [dataLoaded, setDataLoaded] = useState(false);
  const { setOriginalFormData, hasChanges } =
    useFormChangeDetection<CustomerItem>(null);

  useEffect(() => {
    if (!dataLoaded) {
      if (customerNames.length === 0) {
        dispatch(loadCustomer());
      }
      if (controllerPorts.length === 0) {
        dispatch(loadAssetControllerPort());
      }
      if (controllerTypes.length === 0) {
        dispatch(loadAssetControllerType());
      }
      setDataLoaded(true);
    }
  }, [
    dispatch,
    dataLoaded,
    customerNames.length,
    controllerPorts.length,
    controllerTypes.length,
  ]);

  useEffect(() => {
    if (assetToEdit) {
      setFormData({
        ...assetToEdit,
        id: assetToEdit.id.toString(),
      });
      setOriginalFormData({ ...assetToEdit });
      setIsEditMode(true);
      setSearchDropdownItems([]);
      openAssetModal();
    }
  }, [assetToEdit, setOriginalFormData]);

  useEffect(() => {
    let isValid = true;
    const newErrors: { [key: string]: string } = {};

    isValid =
      formData.name.trim() !== "" &&
      formData.customerName.trim() !== "" &&
      formData.pocType.id.trim() !== "";

    if (
      formData.name &&
      assets.some(
        (asset) =>
          asset.name &&
          asset.name.toLowerCase() === formData.name.toLowerCase() &&
          (!isEditMode || asset.id !== formData.id)
      )
    ) {
      newErrors.name = "Asset name already exists";
    }

    if (
      formData.portId &&
      assets.some(
        (asset) =>
          asset.portId === formData.portId &&
          asset.nodeAddress.trim().toLowerCase() ===
            formData.nodeAddress.trim().toLowerCase() &&
          (!isEditMode || asset.id !== formData.id)
      )
    ) {
      newErrors.portAndAddress = "Address+Port combination already exists";
    }

    setErrors(newErrors);
    setIsFormValid(isValid && Object.keys(newErrors).length === 0);
  }, [formData, isEditMode, assets]);

  useEffect(() => {
    setInputValue(formData.cnX_WellName);
  }, [formData.cnX_WellName]);

  useEffect(() => {
    if (typeof inputValue === "string" && inputValue.length > 4) {
      const filteredItems = searchResults.filter((result) =>
        `${result.wellName} (${result.apI_UWI})`
          .toLowerCase()
          .includes(inputValue.toLowerCase())
      );
      setSearchDropdownItems(
        filteredItems.map((result, index) => ({
          id: result.id,
          name: result.wellName,
          displayName: result.apI_UWI
            ? `${result.wellName} (${result.apI_UWI})`
            : result.wellName,
          cnX_SiteId: result.siteID,
          envWellID: result.envWellID,
          apI_UWI: result.apI_UWI,
          key: `${result.id}-${index}`,
        }))
      );
    }
  }, [searchResults, inputValue]);

  const openAssetModal = () => setIsAssetModalOpen(true);
  const closeAssetModal = () => {
    setInputValue("");
    setIsAssetModalOpen(false);
    setFormData(initialFormData);
    setErrors({});
    setIsEditMode(false);
  };

  const openAddUserModal = () => {
    setIsEditMode(false);
    setOriginalFormData(initialFormData);
    setFormData(initialFormData);
    openAssetModal();
    setSearchDropdownItems([]);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value.replace(/^\s+/, ""),
    }));
  };

  const handleDropdownChange = (item: DropdownItems, field: string) => {
    setFormData((prevState) => {
      if (field === "type") {
        const pocType = {
          id: item.id,
          lookupType: item.lookupType,
          legacyId: item.legacyId,
          description: item.name,
        };
        return {
          ...prevState,
          pocType,
        };
      }

      return {
        ...prevState,
        [field]: field === "portId" ? item.id : item.name,
        ...(field === "customerName" && { customerId: item.id }),
      };
    });
  };

  const handleSearchDropdownChange = (selectedAsset: searchDropdownItem) => {
    setFormData((prevState: AssetItem) => ({
      ...prevState,
      cnX_WellId: selectedAsset.id ? String(selectedAsset.id) : null,
      cnX_WellName: selectedAsset.name,
      name: !isEditMode ? selectedAsset.name : prevState.name,
      cnX_SiteId: selectedAsset.cnX_SiteId
        ? String(selectedAsset.cnX_SiteId)
        : null,
      envWellId: selectedAsset.envWellID
        ? String(selectedAsset.envWellID)
        : null,
      apI_UWI: selectedAsset.apI_UWI ? String(selectedAsset.apI_UWI) : null,
    }));
    setInputValue(selectedAsset.name);
  };

  const handleAssetSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const currentDate = new Date().toISOString();
    const matchedControllerType = controllerTypeItems.find(
      (type) => type.id === formData.pocType?.id
    );

    const updatedFormData: AssetItem = {
      ...formData,
      pocType: {
        ...formData.pocType,
        description: matchedControllerType
          ? matchedControllerType.name
          : formData.pocType.description,
      },
      cnX_SiteId: formData.cnX_SiteId ?? 0,
      cnX_WellId: formData.cnX_WellId ?? 0,
      envWellId: formData.envWellId ?? 0,
      apI_UWI: formData.apI_UWI ?? null,
      createdOnDate: formData.createdOnDate
        ? formData.createdOnDate
        : currentDate,
      lastModifiedDate: currentDate,
    };

    if (Object.keys(errors).length === 0) {
      try {
        const action = isEditMode
          ? editAsset({
              assetId: formData.id,
              updatedAsset: updatedFormData,
            })
          : addAsset(updatedFormData);

        await dispatch(action).unwrap();
        setToastMessage(
          `Asset ${isEditMode ? "updated" : "added"} successfully`
        );
        setTextColor("green");
        dispatch(loadAsset());
      } catch (error) {
        setToastMessage(
          `Failed to ${isEditMode ? "update" : "add"} asset. Please try again.`
        );
        setTextColor("red");
      } finally {
        setShowToast(true);
        closeAssetModal();
      }
    }
  };

  const addCustomerHandler = (newCustomer: CustomerItem) => {
    dispatch(addCustomer(newCustomer));
  };

  const customNoResultsMessage = (
    <>
      <p className="no-customer-found">Customer not listed?</p>
      <span className="add-new-customer">
        <AddCustomer addCustomer={addCustomerHandler} triggerType="button" />
      </span>
    </>
  );

  const handleInputChangeLogging = async (value: string) => {
    const trimmedValue = value.replace(/^\s+/, "");
    setInputValue(trimmedValue);

    if (trimmedValue.length === 5) {
      dispatch(searchAssetByName(trimmedValue));
    }
  };

  const handleCancelClick = () => {
    if (hasChanges(formData)) {
      setIsConfirmationDialogOpen(true);
    } else {
      handleConfirmCancel();
    }
  };

  const handleConfirmationDialogClose = () =>
    setIsConfirmationDialogOpen(false);

  const handleConfirmCancel = () => {
    setIsConfirmationDialogOpen(false);
    closeAssetModal();
  };

  const customerItems = customerNames
    .map((customer) => ({
      id: customer.id,
      name: customer.name || "",
    }))
    .sort((a, b) => (a.name || "").localeCompare(b.name || ""));

  const controllerPortItems = controllerPorts
    .map((port) => ({
      id: port.id,
      name: `${port.serverName}/${port.portID}/${port.description}`,
      portID: Number(port.portID),
    }))
    .sort((a, b) => a.portID - b.portID);

  const controllerTypeItems = controllerTypes
    .map((type) => ({
      id: type.id,
      lookupType: type.lookupType,
      legacyId: type.pocType,
      name: type.description || "",
    }))
    .sort((a, b) => (a.name || "").localeCompare(b.name || ""));

  const matchedControllerType = controllerTypeItems.find(
    (type) => type.id === formData.pocType?.id
  );

  const matchedControllerPort = controllerPortItems.find(
    (port) => port.id === formData.portId
  );

  return (
    <div>
      <Tooltip content="Add new asset" direction="top">
        <span className="add-user-icon" onClick={openAddUserModal}>
          <AddAssetIcon />
        </span>
      </Tooltip>

      <Modal isOpen={isAssetModalOpen} onClose={closeAssetModal}>
        <div style={{ position: "relative" }}>
          {assetSaveLoading && (
            <div className="spinner-overlay">
              <Loader isOverlay={true} />
            </div>
          )}
          <form className="add-user-form" onSubmit={handleAssetSubmit}>
            <div className="form-row">
              <label style={{ fontSize: "20px" }}>
                {isEditMode ? "Edit well" : "Create a new well"}
              </label>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>CNX Name (API UWI Number)</label>
                <SearchDropdown
                  items={searchDropdownItems}
                  defaultOption={formData.cnX_WellName || "Select a well"}
                  onChange={handleSearchDropdownChange}
                  onInputChange={handleInputChangeLogging}
                  inputValue={inputValue}
                  isLoading={assetSearchLoading}
                  infoMessage="Type 5 or more characters to search"
                />
              </div>
              <div className="form-group">
                <label>
                  <span>Name</span>
                  <span className="required-asterisk">*</span>
                </label>
                <div className="alert-icon-container">
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                  {errors.name && (
                    <span className="error-icon">
                      <Tooltip content={errors.name} direction="top">
                        <AlertIcon />
                      </Tooltip>
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="form-row">
              <label>Controller:</label>
            </div>
            <div className="form-row">
              <div className="form-group">
                <div className="form-group">
                  <label>
                    <span>Type:</span>
                    <span className="required-asterisk">*</span>
                  </label>
                  <Dropdown
                    items={controllerTypeItems}
                    defaultOption={
                      matchedControllerType
                        ? matchedControllerType.name
                        : "Select type"
                    }
                    onChange={(item) => handleDropdownChange(item, "type")}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>
                  <span>Customer Name:</span>
                  <span className="required-asterisk">*</span>
                </label>
                <Dropdown
                  items={customerItems}
                  defaultOption={formData.customerName || "Select customer"}
                  onChange={(item) =>
                    handleDropdownChange(item, "customerName")
                  }
                  noResultsMessage={customNoResultsMessage}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>Port:</label>
                <div className="alert-icon-container">
                  <Dropdown
                    items={controllerPortItems}
                    defaultOption={
                      matchedControllerPort
                        ? matchedControllerPort.name
                        : "Select port"
                    }
                    onChange={(item) => handleDropdownChange(item, "portId")}
                  />
                  {errors.portAndAddress && (
                    <span className="error-icon">
                      <Tooltip content={errors.portAndAddress} direction="top">
                        <AlertIcon />
                      </Tooltip>
                    </span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>Address:</label>
                <div className="alert-icon-container">
                  <input
                    type="text"
                    name="nodeAddress"
                    value={formData.nodeAddress}
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                  {errors.portAndAddress && (
                    <span className="error-icon">
                      <Tooltip content={errors.portAndAddress} direction="top">
                        <AlertIcon />
                      </Tooltip>
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="form-row">
              <div
                className="form-group form-group-inline"
                style={{ marginTop: "10px" }}
              >
                <label htmlFor="isEnabled">Is Enabled:</label>
                <input
                  type="checkbox"
                  id="isEnabled"
                  name="isEnabled"
                  checked={formData.isEnabled}
                  onChange={handleInputChange}
                  disabled={!formData.portId || !formData.nodeAddress}
                  style={{ width: "15px", marginTop: "0px" }}
                  className="asset-checkbox"
                />
              </div>
            </div>
            <div className="form-row">
              <p className="mandatory-fields-message">
                Fields marked with<span className="required-asterisk">*</span>{" "}
                are mandatory.
              </p>
            </div>
            <div className="form-row" style={{ gap: "10px" }}>
              <button
                type="submit"
                className="add-user-button"
                disabled={
                  isEditMode
                    ? !isFormValid || !hasChanges(formData)
                    : !isFormValid
                }
              >
                {isEditMode ? "Save Changes" : "Add Well"}
              </button>
              <button
                type="button"
                className="cancel-button"
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Modal>
      {showToast && (
        <SuccessfullToast
          message={toastMessage}
          isShowEnabled={showToast}
          setShow={setShowToast}
          colorText={textcolor}
        />
      )}
      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        onClose={handleConfirmationDialogClose}
        onConfirm={handleConfirmCancel}
        message="Are you sure you want to cancel? Any unsaved changes will be lost."
      />
    </div>
  );
};

export default AddAssetDetails;
